<template>
    <div class="my-resource">
        <navbar title="我的资源" />
        <div class="resource-box" v-if="status==0">
      <div class="resource-item" v-for="item in itemList" :key="item.id">
        <div class="resource-content">
            <div class="resource-icon">{{ item.realName.slice(0,1) }}</div>
              <div class="item-inner">
                  <div class="title"> 
                       <div class="item-phone">{{ item.mobile}}</div>
                       <div class="item-btn"><van-button type="primary" @click="goSuccess(item.id)">抢客源</van-button></div>
                  </div>
              <div class="item-intention">意向房源：{{ item.houseTitle }}</div>
              <div class="item-time">报名时间：{{ getTimes.timestampToTime(item.createTime) }}</div>
            </div>
          </div>
         
      </div>
      </div>
      <div v-if="status==1" class="empty-resource">
          <div class="empty-img"><img src="../../assets/images/201903zzy.png"></div>
          <div class="empty-word">暂无可推荐的资源</div>
      </div>
    </div>
</template>
<script>
import navbar from "../../components/NavBar.vue";
export default ({
    name:"MyResource",
    data(){
        return{
            status:0,
            itemList:[
                 {
                     id:0,
                     icon:"郑",
                     intention:"测试小区",
                     phone:"150****9400",
                     time:"2021-12-22 16:12:31",
                 },
                 {
                     id:1,
                     icon:"郑",
                     intention:"测试小区",
                     phone:"150****9400",
                     time:"2021-12-22 16:12:46",
                 },
            ],
        };
    },
  mounted() {
      this.getResources();
  },
  methods: {
    goSuccess(id) {
      console.log("item", id)
      const data = {
        id: id
      }
      this.$post("user/getTouristSource", data).then((res) => {
        if (res.status == 200) {
          this.$dialog.alert({
            message: '抢客成功！',
          });
          this.getResources();
        }else {
          this.$toast.fail(res.msg);
        }
      })
    },
    getResources() {
      console.log("获取我的资源")
      this.$post("user/getResources").then((res) => {
        if (res.status == 200) {
          console.log(res.data.list)
          this.itemList = res.data.list
        }else {
          this.$toast.fail(res.msg);
        }
      })
    },
  },
  components: {
    navbar,
  },
})
</script>
<style lang="less" scoped>
.my-resource{
    .resource-box{
        .resource-item{
             background: #fff;
             margin-top: 8px;
             padding: 10px;
             font-size: 12px;
             color: #888;
             line-height: 1 !important;
             .resource-content{
                 flex:1;
                 border-bottom: 1px solid #eee;
                 display: flex;
                 padding-bottom: 10px;
             }
             .resource-icon {
            width: 33px;
            height: 33px;
            border-radius: 50%;
            background: #5bb3f9;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            margin-right: 5px;
          }
        }
    }
}
 .item-inner{
              width:100%;
              .title{
                  display: flex;
                  justify-content: space-between;
              }
              .van-button--primary{
                  width:54px;
                  height:23px;
                  border:none;
                  background: #00D3C4;
                  color: white;
              }
              /deep/.van-button--normal{
                  padding:0px;
              }
              .item-phone{
                  padding: 5px;
              }
              .item-intention{
                  display: flex;
                   padding: 5px;
                   p{
                       color: #551A8B;
                   }
              }
              .item-time{
                  padding: 5px; 
              }
          }
.empty-resource{
    width: 100%;
    text-align: center;
    margin-top: 25vh;
    .empty-img{
        img{
            width:62px;
            height:48px;
        }
    }
    .empty-word{
        font-size: 12px;
        color: #444444;
    }
}
</style>
